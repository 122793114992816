nav {
  padding: 0px 12px 0px 0px;
  text-transform: uppercase;
  font-family: var(--primary_fnt);
  letter-spacing: 3px;
  font-size: 14px;
  font-weight: 400;
  background: #fff;
}

nav ul {
  max-width: 1162px;
  width: 100%;
  margin: auto;
}

nav ul li {
  display: inline-block;
  margin: 14px 14px 7px 14px;
  background: var(--bg_clr);
  color: var(--secondary_accent_clr);
  box-shadow: 0px -4px 4px 0px #fffa inset;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}


nav ul li.active a {
  background: var(--accent_clr);
  color: var(--bg_clr);
  padding: 7px 24px;
  box-shadow: none;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

nav ul li a {
  padding: 7px 24px;
  text-decoration: none;
  color: var(--secondary_accent_clr);
  width: 100%;
}

nav ul li.active a {
 color: #f3f3a3
}
