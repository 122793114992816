@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffffc8;
}

:root {
  --bg_clr: #dbdbeb;
  --accent_clr: #3a3b54;
  --secondary_accent_clr: #333333;
  --primary_fnt: 'Fredoka', sans-serif;
  --primary_gradient: linear-gradient(to bottom right, #fffff1, #d9ebee);
  --secondary_gradient: linear-gradient(to bottom right, #fffff1, #f0f3dc);
}

body {
  background: var(--bg_clr);
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* // -=-=-=-=-=-=-=-=-=-=-
//  		THEME STYLING
// -=-=-=-=-=-=-=-=-=-=-

// CONTAINERS */

.App header.MuiAppBar-root
{	position: sticky;
	top: 0}

.App header .MuiLink-underlineHover
{	text-decoration: none!important;}

/* // FONTS */

.small-font
{	font-size: 0.75em;}

.black-link
{    color: #000;
    text-decoration: none}

.green
{  color: #0d5}

.turquoise-font
{	color: #28B3AC !important}

.blue-font
{	color: #0076C0 !important}

.grey-font
{	color: #a0a0a0 !important}

.bold
{  font-weight: 700}

.big-icon
{	font-size: 8em !important}
/*
// ALIGNMENT */

.ml-14
{  margin-left: 14px}

.centered
{	margin: auto}

/* BUTTONS */

.btn-small
{	font-size: 0.75em !important;
	font-weight: 700 !important}

.btn-dark
{	background: #555 !important;
	color: #fff !important}

.btn-rounded
{	border-radius: 20px !important;
	text-transform: none !important}

.btn-center
{	text-align: center !important}

.btn-padded
{	padding: 7px 21px !important}

.btn-full
{	width: 100% !important}

.btn-theme
{  text-align: center;
  width: 100%;
  border-radius: 14px;
  padding: 4px 7px;
  border: none;
}

.MuiButton-contained.btn-theme {
  background-color: #b1ff63 !important;
}

.MuiButton-outlined.theme-table-btn {
  /* background: #34113f;
  color: var(--bg_clr); */
  font-family: 'Fredoka', sans-serif;
}

.btn-theme-secondary
{  text-align: center;
  width: 100%;
  border-radius: 14px;
  padding: 4px 7px;
  border: none;
  background: #009EE3 !important;
  color: #fff !important}

.MuiInputLabel-root
{	font-weight: 600!important;
	font-size: 0.875rem!important}

/* INPUTS */

.input-theme
{	width: calc(100% - 14px);
	border-radius: 5px !important}

.MuiOutlinedInput-root
{	border-radius: 0px !important}

.select-input
{	border-radius: 5px !important;
	height: 2.369em !important}

.MuiIconButton-colorSecondary
{	color: #0076C0}

.MuiCheckbox-root
{	color: rgba(0, 0, 0, 0.23) !important}

.MuiCheckbox-colorSecondary.Mui-checked
{	color: #0076C0 !important}

/* MISC */

.logo-round
{  width: 140px;
  height: 100px;
  margin: auto;
  margin-bottom: 14px}

.error-label
{  margin-top: 2px;
  font-size: 0.75em !important;
  color: #f00 !important;
  font-weight: 600 !important}

.success-label
{  margin-top: 2px;
  font-size: 0.75em !important;
  color: #28B3AC !important;
  font-weight: 600 !important}

.MuiToggleButton-root.Mui-selected {
    color: #fffcad !important;
    background-color: var(--accent_clr) !important;
}

.MuiToggleButtonGroup-root {
  border: 1px solid var(--accent_clr) !important;
}

.MuiToggleButton-root:not(.Mui-selected) .MuiToggleButtonGroup-groupedHorizontal {
  border: 1px solid #cacaca !important;
}

.MuiOutlinedInput-inputMarginDense {
    padding-top: 10.5px;
    padding-bottom: 10.5px;
    border: 1px solid #d2d4ff !important;
    border-radius: 5px;
}


::-webkit-scrollbar {
  width: 5px;
  transition: 0.2s;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--accent_clr);

}


::-webkit-scrollbar:hover {
  width: 10px;
}
