.teacher-select {
  min-width: 100%;
  margin-top: 14px;
}

.confirm-teacher.MuiButton-outlined {
  margin-top: 14px;
  min-width: 100%;
}

.main-dashboard a {
  text-decoration: none;
}
