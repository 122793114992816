.column-vertical-align-center{
  display: flex;
  align-items: center;
  height: 100%;
}

.student-dashboard-next-lesson-materials-table {
  width: 100%;
  margin-top: 14px;
  font-family: 'Fredoka One', sans-serif;
  font-size: 0.8rem;
}

.student-dashboard-next-lesson-materials-table tr td {
  padding: 14px 0;
}

.student-dashboard-schedule-table tr td {
  padding: 7px 0;
}

.student-dashboard-next-lesson-materials-table tr td:last-child,
.student-dashboard-schedule-table tr td:last-child {
  text-align: right;
  padding-right: 7px;
}

.student-dashboard-next-lesson-materials-btn {
  background: var(--accent_clr) !important;
  color: #fff !important;
  font-size: 0.75rem !important;
  border-radius: 11px !important;
}

.student-dashboard-schedule-table {
  font-family: "Fredoka One", sans-serif;
  width: 100%;
  font-size: 0.9rem;
}

.student-dashboard-schedule-table tr {
  border-bottom: 1px solid #cacaca;
}


.student-dashboard-schedule-btn {
  /* background: var(--accent_clr) !important; */
  /* color: #fff !important; */
  font-size: 0.75rem !important;
  border-radius: 11px !important;
}
