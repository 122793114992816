.login {
  width: 100vw;
  min-height: 100vh
}
.login .form-container {
  margin: auto;
  padding: 35px 14px;
  width: 90%;
  max-width: 320px;
  background: #fffe;
  transition: 0.4s;
  transition-timing-function: ease-out;
  border-radius: 24px;
  filter: saturate(1) hue-rotate(0deg);

}

.login .form-container.faded-left {
  transform: translate(00px, 60px);
  opacity: 0
}

.login .form-container.faded-right {
  transform: translate(60px);
  opacity: 0
}

.logo-round {
  background-image: url('../../../assets/images/magic-sprout-logo.png');
  background-size: cover;
  width: 180px;
  height: 40px;
  margin: auto;
}

.left-col {
  background: #f00;
  min-height: 100vh
}


.login .left-col
{  background: rgb(240,240,240);
  background: linear-gradient(55deg, rgba(240,240,240,1) 0%, rgba(0,118,192,0.3029586834733894) 40%, rgba(40,179,172,0.3029586834733894) 80%, rgba(240,240,240,1) 100%);
  background-image: url('../../../assets/images/magic-sprout-login.png');
  background-size: cover

}

.right-col
{  background: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url('../../../assets/images/wooden-noticeboard.png');
  background-size: cover;
  background-position: 0% 33.333%;
  filter: saturate(1.5) hue-rotate(13deg);
  border-left: 8px solid #344a3fcc;
    box-shadow: -4px 0px 8px 8px #344a3fcc;

}

.form-group
{  height: 80px}

.form-title
{  font-size: 1.333em;
  text-align: center;
  margin: 18px 18px;
  color: #53a556;
}

.form-label
{  display: block;
    font-family: var(--primary_fnt) !important; font-weight: 400 !important; }

.form-submit
{  text-align: center;
  width: 100%;
  border-radius: 14px;
  padding: 4px 7px;
  border: none;
  background: #555;
  color: #fff;
  margin-bottom: 14px;
margin-top: 7px;}


.form-link
{  margin-bottom: 14px;
  text-align: center;
  font-weight: 700 !important;
  font-family: sans-serif;
  text-decoration: none;
  display: block;}

.form-link:nth-of-type(1) {
  margin-top: 14px;
}

.form-group .MuiFormControl-root {
  width: 100%;
}

.form-group .MuiFormControl-root  input {
    font-family: var(--primary_fnt) !important;
}

.centered {
  margin: auto;
}
