.modal-scroll-container {
  height: 580px;
  overflow-y: scroll;
  overflow-x: hidden;
  position:relative;
}

.modal-scroll-container.no-overflow {
  overflow-y: hidden;
}

.alt-view-container {
  background: #fffb;
  position: absolute;
  left: 100%;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 20px;

  transition: 0.2s;
}

.alt-view-inner-container {
  width: 80%;
  margin-left: 10%;
  border-radius: 14px;
  border: 1px solid #aaa;
  box-shadow: 0px 2px 4px 0px #0003;
  height: 100%;
  background: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.alt-view-container.visible {
  left: 0%;
}

.close-btn {
  cursor: pointer;
  position: absolute;
  font-family: 'Fredoka', sans-serif;
  right: 14px;
  top: 14px;
  text-align: center;
  font-size: 16px;
  line-height: 16px;
  width: 24px;
  height: 24px;
  vertical-align: center;
  border-radius: 100%;
  border: 1px solid #cacaca;
  font-weight: 700;
  z-index: 9;
}
