a.button-link {
  text-decoration: none;
}

.table-controls-container {
  height: 60px;
  border-bottom: 2px dotted #cacaca;
}

.invoice-dashboard h6 {
  font-family: var(--primary_fnt);
  color: #444;
  margin-bottom: 20px;
}

.invoice-dashboard .trial-table h6 {
  border-bottom: 2px solid #ff2424aa;
}

.invoice-dashboard .unpaid-table h6 {
  border-bottom: 2px solid #ffea21aa
}

.invoice-dashboard .paid-table h6 {
  border-bottom: 2px solid #4ffc91aa;
}

.invoice-dashboard h6:nth-of-type(4) {
  border-bottom: 2px solid #7272f6;
}
