.teacher-dashboard {
  background: #fff4d3;
  min-height: 100vh;
}

.teacher-card {
  background: rgb(145,195,139);
  color: #fff;
  background: linear-gradient(90deg, rgba(135,185,129,1) 0%, rgba(81,140,99,1) 100%);
}

.calendar-day-card-container {
  width: 100%;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calendar-day-card {
  height: 96px;
  width: 96px;
  background: #fff;
  box-shadow: 0px 1px 2px 0px #0007;
  border-radius: 7px;
  margin: 8px;
  cursor: pointer;
  transition: 0.15s;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.calendar-day-card.active {
  background: var(--accent_clr);
  color: #fff;
}

.calendar-day-card-container .calendar-day-card:first-child {
  margin-left: 0
}

.calendar-day-card-container .calendar-day-card:last-child {
  margin-right: 0
}


.lesson-card-container {
  border-radius: 14px;
  /* background: #eaeaea;
  padding: 12px; */
  box-sizing: border-box;
  margin: 14px 0;
  width: 100%;
  /* height: 160px; */
  display: flex;
  justify-content: center;
}

.lesson-card-container .MuiAccordion-root {
  width: 100%;
}

.trial-indicator {
  position: absolute;
  right: 7px;
  top: 7px;
  height: 7px;
  width: 7px;
  background: #f00a;
  border-radius: 100%;
}

.lesson-indicator {
  height: 7px;
  width: 7px;
  background: #d0d0d0;
  border-radius: 100%;
}

.lesson-indicator-container {
  width: 100%;
  /* height: 24px; */
  position: absolute;
  bottom: 4px;
  text-align: center;
  /* border: 1px solid; */
  left: 0;
  display: flex;
  justify-content: center;
}

.lesson-card-left-text-container {
  border-right: 1px solid #cacaca;
  padding-right: 14px;
}

.lesson-card-right-text-container {
  padding-left: 21px;
}

.lesson-card-left-section {
  width: 77px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lesson-card-right-section {
  width: calc(100% - 64px);
}

.report-table-container {
  max-height: 196px;
  overflow-y: scroll;
  margin-top: 14px;
}

.report-table {
  text-align:center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.8rem;
  width: 100%;
}

.report-table tr:nth-of-type(odd) {
  background: #e0e0e0;
}

.report-table tr:nth-of-type(even) {
  background: #f0f0f0;
}

.report-table td {
  padding: 7px 0;
}

.report-table button {
  margin: 0;
  padding: 3px 7px;
  background: transparent;
  border: none;
  border-radius: 4px;
  background-color: var(--accent_clr);
  color: #fff;
  font-family: var(--primary_fnt);
  font-weight: 300;
  font-size: 0.8rem;
  cursor: pointer;
}
