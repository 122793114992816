table.sprout-admin-table {
  width: 100%;
  margin: auto;
  border-radius: 14px;
  overflow: hidden;
  font-family: 'Helvetica', sans-serif;
  background: var(--primary_gradient);
  box-shadow: 0px 0px 2px 0px #7289a1aa;
}

table.sprout-admin-table th {
  border: 1px solid #f1f1f1;
  /* background: var(--accent_clr); */
  padding: 12px 24px;
  font-family: var(--primary_fnt);
  background: #eaeaea;
  border-bottom: 2px solid #e8f3ef;

}

table.sprout-admin-table td {
  border: 1px solid #f1f1f1;
  text-align: center;
  padding: 12px 24px;
  font-family: var(--primary_fnt);
  font-weight: 300;
  background: #ffffffe0;
}

table.sprout-admin-table tr:nth-of-type(2n) td {
  background: #f7f4f722;
}
