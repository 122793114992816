.react-calendar {
    overflow: hidden;
    border-radius: 7px;
    border: 1px solid #006edc;
    width: calc(100% - 7px) !important;
}

.calendar-container .react-calendar__month-view__days__day--weekend {
    /* color: initial; */
}

abbr:where([title]) {
    text-decoration: none !important;
}

.react-calendar__tile:disabled {
    color: #ccc;
    background: initial !important;
}

.calendar-container .react-calendar__tile--now {
    background: #dfefff;
}

.calendar-container .react-calendar__tile--active {
    background: #006edc;
}

.calendar-container  .react-calendar__navigation button:disabled {
    color: #ccc;
    background: initial; 
}

.blackout-tile {
    background: #518c63 !important;
    color: #fff;
}

.weekend-tile:not(.blackout-tile) {
    color: #000 !important;
}

.weekend-tile {
    color: #fff !important;
}