.form-container {
  box-sizing: border-box;
  padding: 14px;
}

.form-group { height: auto; position: relative;}

.form-title {
  font-size: 1.777em;
  text-align: center;
  margin-bottom: 14px;
  color: #28B3AC;
}

.form-label
{  display: block; }

.form-group .MuiInputLabel-root.converted-time-label.form-label {
  margin-bottom: 14px;
  margin-top: -18px;
}

/* .converted-time-label.form-label .MuiInputLabel-root {
  margin-top:0
} */

.cet-time {
  font-family: 'Fredoka', sans-serif !important;
  transform: translateY(-2px) !important;
}

.form-submit {
  text-align: center;
  width: 100%;
  border-radius: 14px;
  /* padding: 4px 7px;
  border: none; */
  background: #555;
  color: #fff;
  margin-bottom: 14px
}


.form-link
{  margin-bottom: 14px;
  text-align: center;
  font-weight: 700 !important;
  font-family: sans-serif;
  text-decoration: none;
  display: block;}

  .form-container .MuiButton-root.form-btn {
    margin-top: 28px;
  }

.form-link:nth-of-type(1) {
  margin-top: 14px;
}

.form-group .MuiFormControl-root {
  width: 100%;
}

.col-6 .form-group .MuiFormControl-root {
  width: 99%;
}

.form-group .col-6 .MuiFormControl-root {
  width: 95%;
}

.form-container .col-3 .MuiFormControl-root {
  width: 98.5%;
}

.col-4 .form-group .MuiFormControl-root {
  width: 95%;
}

.form-group .MuiInputLabel-root {
  margin-bottom: 4px;
  margin-top: 14px;
}

.form-container .MuiToggleButton-root {
  min-width: 80px;
}

.form-container .MuiToggleButtonGroup-root {
  height: 42px;
}

.form-container .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense {
  padding: 0;
  height: auto;
  line-height: 1.5rem;
  /* height: 1.5rem; */
}

.form-container .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense textarea {
  padding: 14px 11px;
}

.form-container .MuiOutlinedInput-root {
  min-width: 95%;
  height: 42px;
}

.number-form-controls {
  position: absolute;
  right: 20px;
  top: 20px;
  background: #fff;
  width: 40px;
  height: 38px;
}

.number-form-controls .up-arrow {
  position: absolute;
  right: 12px;
  top: 1px;
  transform: rotate(180deg)
}

.number-form-controls .down-arrow {
  position: absolute;
  right: 12px;
  top:  21px;
}

.down-arrow, .up-arrow {
  /* background: #ccc;
  border-radius: 100%; */
  padding: 2px;
  font-size: 0.75rem;
  cursor: pointer;
}
