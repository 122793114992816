.modal-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #000c;
  z-index: 9;
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-inner-container {
  border-radius: 12px;
  box-shadow: 0px 2px 2px 2px rgba(200,200,200,1);
  background: #fff;
  min-height: 420px;
  width: 96%;
  max-width: 768px;
  transition: 0.5s;
  position: relative;
}

.modal-close-button {
  position: absolute;
  color: #000;
  right: 0px;
  top: 24px;
  width: 48px;
  height: 48px;
  transform: scale(1.25);
  cursor: pointer;
  z-index: 9;
}

.modal-closed .modal-inner-container {
  transform: translate(0 100vh);
}

.modal-open .modal-inner-container {
  transform: translate(0 0);
}

.modal-closed {
  opacity: 0;
  z-index: -1;
}

.modal-open {
  top: 0;
  opacity: 1;
}
