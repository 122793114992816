
.checkbox-table-container {
  /* border: 1px solid #cacaca; */
  width: 100%;
  position: relative;
}

.form-group .checkbox-table-container:first-of-type {
  padding-left: 7px;
}


.checkbox-table-container .checkbox-table {
  /* padding: 7px; */
  border: none;
  width: 100%;
}

.checkbox-table-container tr {
  width: 100%;
}

.checkbox-table-container tr td {
  padding: 7px 0;
}

.checkbox-table-container tr.partly-complete {
  background: #ffe69c !important;
}

.checkbox-table-container tr.complete {
  background: #81ff94 !important;
}

.checkbox-table tr:nth-of-type(even) {
  background: #eaeaea;
}

.checkbox-table tr:nth-of-type(odd) {
  background: #f8f8f8;
}

.checkbox-table-container tr td:first-child {
  text-align: center;
  /* background: #dedede; */
  min-width: 64px;
}

.checkbox-table-container tr td:nth-child(2) {
  padding-left: 7px;
  /* font-weight: 200 ! important; */
  font-size: 0.8rem;

  font-family: 'Fredoka One', sans-serif !important;
}

.checkbox-table-pdf-preview {
  position: fixed;
  top: 0px;
  /* left: 120px; */
  height: 240px;
  width: 320px;
  right: 0;
  /* border: 1px solid #f00; */
  z-index: 1;
}

.checkbox-table-pdf-preview .pdf-reader-container {
  width: 100%;
  /* height: calc(100vh - 105px); */
  box-sizing: border-box;
  /* padding-left: 48px;
  padding-top: 28px;
  margin-top: -73px; */
  overflow-x: hidden;
  overflow-y: hidden;
}

.checkbox-table-container:nth-of-type(odd) .checkbox-table-pdf-preview {
  right: 0px;
}

.checkbox-table-container:nth-of-type(even) .checkbox-table-pdf-preview {
  left: 0;
}


.checkbox-table-pdf-preview .pdf-reader-container .react-pdf__Page {
    margin-bottom: 14px;
    width: calc(100%);
}

.checkbox-table-pdf-preview .pdf-reader-container .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
}

.file-preview-uri-dev {
  position: fixed;
  left: 0;
  bottom: 0;
  background: #fff;
  padding: 7px;
}
