table.sprout-availability-table {
  width: 100%;
  margin: auto;
  border-radius: 14px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  overflow: hidden;
  font-family: 'Helvetica', sans-serif;
  background: var(--secondary_gradient);
  box-shadow: 0px 0px 2px 0px #7289a1aa;
  margin-bottom: 24px;
}

table.sprout-availability-table th {
  border: 1px solid #f1f1f1;
  /* background: var(--accent_clr); */
  padding: 24px 24px;
  font-family: var(--primary_fnt);
  border-bottom: 2px solid #e8f3ef;
}

table.sprout-availability-table td {
  border: 1px solid #f1f1f1;
  text-align: center;
  padding: 12px 24px;
  height: 24px;
  font-family: var(--primary_fnt);
  font-weight: 300;
  background: #ffffffe0;
  position: relative;
}

table.sprout-availability-table tr:nth-of-type(2n) td {
  background: #f7f4f722;
}

.lesson-item {
  background: #3939dbd6;
  height: 100%;
  width: 100%;
  top:0;
  border-radius: 7px;
  position: absolute;
  left: 0;
  z-index: 9;
  border: 2px solid #45246a;
}
/* table.sprout-availability-table td.available {
  background: #0f01;
}

table.sprout-availability-table tr:nth-of-type(2n) td.available {
  background: #0f02;
} */

table.sprout-availability-table td.unavailable {
  background: #cacaca;
  border: none;
}

table.sprout-availability-table tr:nth-of-type(2n) td.unavailable {
  background: #c0c0c0;
  border: none;
}

table.sprout-availability-table td.availability-table-time-left p {
  transform: translate(28px, -12px) scale(0.75);
  font-size: 1em;
  height: 18px;
}

table.sprout-availability-table td.availability-table-time-right p {
  transform: translate(-28px, -12px) scale(0.75);
  font-size: 1em;
  height: 18px;
}


.alt-view-container {
  background: #fffb;
  position: absolute;
  left: 100%;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 20px;

  transition: 0.2s;
}

.alt-view-inner-container {
  width: 80%;
  margin-left: 10%;
  border-radius: 14px;
  border: 1px solid #aaa;
  box-shadow: 0px 2px 4px 0px #0003;
  height: 100%;
  background: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.alt-view-container.visible {
  left: 0%;
}

.close-btn {
  cursor: pointer;
  position: absolute;
  font-family: 'Fredoka', sans-serif;
  right: 14px;
  top: 14px;
  text-align: center;
  font-size: 16px;
  line-height: 16px;
  width: 24px;
  height: 24px;
  vertical-align: center;
  border-radius: 100%;
  border: 1px solid #cacaca;
  font-weight: 700;
  z-index: 9;
}

.availability-cell .MuiButtonBase-root {
 padding: 0;
 height: 13px;
}
