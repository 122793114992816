.gpt3-header {
  font-family: var(--primary_fnt) !important;
  padding: 7px !important
}

.sentence-table td {
  position : relative;
  padding-right: 64px !important;
}

.sentence-table td {
  width: 50%;
}

.sentence-refresh-btn {
  position: absolute;
  right: 7px;
  top: 25%;
  cursor: pointer;
}

.sentence-edit-btn {
  position: absolute;
  right: 35px;
  top: 25%;
  cursor: pointer;
}

.empty-sentences-info {
  position: absolute;
  padding: 14px;
  text-align: center;
  width: 100%;
  transform: translateY(90px);
  font-weight: 700 !important;
}

.selected-image-container {
  position: absolute;
  background: #000a;
  width: 100%;
  height: 100%;
  top: 1px;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected-image-btn {
  font-family: var(--primary_fnt);
  background: #fff;
  border: none;
  border-radius: 7px;
  font-size: 1.25rem;
  padding: 7px 21px;
}

.selected-image-container img {
  border-radius: 7px;
  margin-bottom: 7px;
  border: 1px solid #cacaca;
}

.word-images-container {
  display: flex;
  justify-content: flex-start;
  border: 1px solid #cacaca;
  border-radius: 7px;
  max-width: 800px;
  margin: auto;
  padding: 4px 4px;
}

.word-images-container img {
  max-width: 200px;
  margin: 2px;
}
