.accordion-container .MuiAccordionSummary-content .MuiTypography-subtitle1,
.accordion-container .MuiAccordionDetails-root .MuiTypography-subtitle1  {
      font-family: var(--primary_fnt) !important;
}

.accordion-container .MuiAccordionDetails-root .MuiTypography-subtitle1 {
  padding: 11px 14px;
  cursor: pointer;
}

.accordion-container .MuiAccordionDetails-root {
  display: block;
}

.MuiAccordionSummary-root.Mui-expanded {
  background: var(--accent_clr);
  color: #f3f3a3;
}

.accordion-container {
  margin-top: 73px;
}

.accordion-container .MuiAccordionDetails-root .MuiTypography-subtitle1:hover  {
      background: #eaeaea;
      /* color: #f3f3a3; */
}

.accordion-container .MuiAccordionDetails-root .MuiTypography-subtitle1:first-child {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.accordion-container .MuiAccordionDetails-root .MuiTypography-subtitle1:last-child {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.lesson-plan-dashboard .pdf-reader-container {
  width: 100%;
  height: calc(100vh - 105px);
  box-sizing: border-box;
  padding-left: 48px;
  padding-top: 28px;
  margin-top: -73px;
  overflow-x: hidden;
}



.lesson-plan-dashboard .pdf-reader-container .react-pdf__Page {
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.5);
    margin-bottom: 14px;
    width: calc(100% - 3px);
}

.lesson-plan-dashboard .pdf-reader-container .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
}

.MuiToggleButtonGroup-root {
    height: 30px;
    margin-bottom: 7px;
}

.toggle-group-smaller-font .MuiToggleButton-label {
  font-size: 0.75rem !important;
}

.MuiAccordionSummary-root.Mui-expanded .word-bank-btn {
  border: 1px solid #fff;
  color: #fff;
  top: 17px;
  opacity: 1;
  display: inline-block;
}

.word-bank-btn {
  display: none;
  opacity: 0;
  top: 10px;
  font-family: var(--primary_fnt);
  padding: 7px;
  border-radius: 7px;
  position: absolute;
  right: 14px;
  border: 1px solid #cacaca;
}

.word-bank-word {
  font-family: var(--primary_fnt);
  color: #fff;
  border-radius: 7px;
  padding: 7px 7px;
  display: inline-block;
  background: var(--accent_clr);
  margin: 4px 4px;
}
.add-new-word-btn {
  margin-left: 4px !important;
  background: #ebebeb !important;
}

.word-bank-word.word-bank-noun {
  border-top: 4px solid #0f0;
}

.word-bank-word.word-bank-adjective {
  border-top: 4px solid #f00;
}

.word-bank-word.word-bank-verb {
  border-top: 4px solid #00f;
}

.word-bank-info-msg {
  font-family: var(--primary_fnt);
  margin: 14px 0;
  color: #000;
  text-align: center;
}

.add-new-word-input .MuiInputBase-root {
  height: 36px;
  margin: 0 7px;
}

.word-bank-select.MuiOutlinedInput-root {
  height: 36px;
  margin: 0 7px;
  width: 124px;
  margin-right: 14px;
}
