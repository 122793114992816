.dashboard-btn.MuiButton-outlined {
  width: 100%;
  margin: 7px 0;
  background: #fff;
  box-shadow: 0px 0px 36px 12px #ffff;
}

.main-dashboard {
  overflow-y: auto;
}

.main-dashboard .card-inner-container {
  box-shadow: 0px 0px 36px 12px #ffff;
  background: transparent !important;
}


/* .rainbows {
  animation: hue 120s linear infinite;
} */

/* .acid-mode {
      filter: url('#wavy') hue-rotate(20deg);
      -webkit-filter: url('#wavy') blur(5px) brightness(1.4) hue-rotate(-366deg) contrast(7) saturate(0.7);
      transform: translate(-0.5vw, -0.5vh) scale(2);
      opacity: 0.75;
      overflow-x: hidden !important;
} */

@keyframes hue {
  from {
    filter: hue-rotate(0deg);
  }
  to {
    filter: hue-rotate(360deg);
  }
}

*, *::before, *::after {
    box-sizing: border-box;
}
