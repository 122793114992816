.example-word-container {
  display: flex;
  justify-content: center;
  background: #f0f0f0;
  width: 100%;
  margin: auto;
  padding: 7px;
  border-radius: 7px;
}

.example-words {
  display: inline-block;
  font-family: var(--primary_fnt) !important;
  margin: 7px 9px !important;
}

.gap-fill-container {
  width: 900px;
  background: #fff;
  margin: auto;
  min-height: calc(100vh - 35px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.exercise-sentences-container {
  padding: 35px 35px;
}

.example-sentences {
    font-family: var(--primary_fnt) !important;
    font-weight: 100 !important;
    margin: 14px 0 !important;
    line-height: 2rem !important;
}

.example-sentences.correct {
  color: #1daf1d;
}

.sentence-text-field input {
  text-align: center !important;
  font-family: var(--primary_fnt) !important;
  font-size: 1.33rem !important;
  transform: translateY(-5px)
}
