.card-container {
  box-sizing: border-box;
  padding: 24px;
  position: relative;
  z-index: 1;
}

.card-inner-container {
  background: #fff;
  border-radius: 7px;
  padding: 24px;
  overflow: auto;
}
