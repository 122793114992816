.react-calendar {
    overflow: hidden;
    border-radius: 7px;
    /* border: 1px solid #006edc; */
    /* width: calc(100% - 7px) !important; */
}

.calendar-container .react-calendar__month-view__days__day--weekend {
    /* color: initial; */
}

abbr:where([title]) {
    text-decoration: none !important;
}

.react-calendar__tile:disabled {
    color: #ccc;
    background: initial !important;
}

.calendar-container .react-calendar__tile--now {
    background: #dfefff;
}

.calendar-container .react-calendar__tile--active {
    background: #006edc;
}

.calendar-container  .react-calendar__navigation button:disabled {
    color: #ccc;
    background: initial; 
}

.blackout-tile {
    background: #35e163 !important;
    color: #fff;
}

.makeup-tile {
    background: #b8d705 !important;
}

.cancelled-tile {
    background: rgb(252, 158, 158) !important;
}

.blackout-tile.clicked-tile {
    background: #99fdb7 !important;
    color: #fff;
}

.blackout-tile.old-date-tile {
    background: #ddd !important
}

.rescheduled-tile {
    background: #5491da !important
}

.hover-tile:not(.blackout-tile):not(.clicked-tile):not(.makeup-tile):hover {
    background: #6e0cde !important;
    color: #fff;
}

.weekend-tile:not(.blackout-tile) {
    color: #000 !important;
}

.weekend-tile {
    color: #fff !important;
}