.prompt-container {
  display: flex;
  justify-content: center;
}

.prompt-select {
  margin: 0 11px;
  min-width: 68px;
}

.prompt-select .MuiSelect-root {
  background: #fff;
}

.prompt-select .MuiOutlinedInput-input {
  padding: 7px 14px;
}

.prompt-field {
  margin-left: 7px;

}

.prompt-field .MuiInputBase-input {
  text-align: center;
  font-weight: 700;
}

.sentence-table {
  border-radius: 14px;
  background: var(--accent_clr);
  width: calc(100% - 120px);
  margin: 60px 60px 30px 60px;
}

.sentence-table td {
  font-family: var(--primary_fnt);
  color: #fff;
  padding: 14px;
  border: 1px solid #cacaca;
}

.results-container {
  padding: 0 25%;
  border: 1px solid #cacaca;
  border-radius: 7px;
  padding: 35px;
  margin-top: 14px;
}

.result-container {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 7px;
}
